/*eslint-disable*/
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import classNames from 'classnames';
import { withRouter } from "react-router-dom";
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/icons
import Favorite from '@material-ui/icons/Favorite';
import { inject, observer } from "mobx-react";
import { compose } from "recompose";
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Parallax from 'components/Parallax/Parallax.jsx';
import Footer from 'components/Footer/Footer.jsx';
// sections for this page

import SectionPictures from './Sections/SectionPictures';

import saveTheDateStyle from 'assets/jss/material-kit-pro-react/views/saveTheDateStyle.jsx';
import { withAuthentication, withAuthorization } from "components/Session";
import { withFirebase } from "../../firebase";
import { when } from 'mobx';

class SaveTheDate extends React.Component {
  constructor(props) {
    super(props);
    console.log('props', props)
    when(() => !this.props.firebase.auth.currentUser, () => this.props.history.push('/login'))

  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Parallax image={require('assets/img/banner.jpg')} filter="dark" small>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                md={8}
                sm={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <img width='100%' src={require('../../assets/img/SavetheDate.png')} />
                <h3 className={classes.title}>Christina and Zachary are getting married!</h3>
              
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <SectionPictures />
          </div>
        </div>
        <Footer
          content={
            <div>
              <div className={classes.right}>
                &copy; {1900 + new Date().getYear()}, made with{' '}
                <Favorite className={classes.icon} /> by Christina and Zach
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

SaveTheDate.propTypes = {
  classes: PropTypes.object
};

const SaveTheDateFirebase =
compose(
  withRouter,
  withFirebase,
  inject("sessionStore"),
  observer
)(SaveTheDate)

export default  withStyles(saveTheDateStyle)(SaveTheDateFirebase);
