/*eslint-disable*/
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Icon from '@material-ui/core/Icon';
import FormHelperText from '@material-ui/core/FormHelperText';
// @material-ui/icons
import Timeline from '@material-ui/icons/Timeline';
import Code from '@material-ui/icons/Code';
import Group from '@material-ui/icons/Group';
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
import Check from '@material-ui/icons/Check';
import Favorite from '@material-ui/icons/Favorite';
import { compose } from 'recompose';
// core components
import Header from 'components/Header/Header.jsx';
import HeaderLinks from 'components/Header/HeaderLinks.jsx';
import Footer from 'components/Footer/Footer.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import InfoArea from 'components/InfoArea/InfoArea.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

import signupPageStyle from 'assets/jss/material-kit-pro-react/views/signupPageStyle.jsx';

import image from 'assets/img/banner.jpg';

import { withFirebase } from '../../firebase';
import { decorate, observable, action, computed } from 'mobx';
import { observer } from 'mobx-react';

class SignUpPage extends React.Component {
  email = '';
  password = '';
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  googleLogin = (event) => {
    event.preventDefault();
    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.props.history.push('/save-the-date');
      })
      .catch((error) => {
        console.log('error', error);
        // if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        //   error.message = ERROR_MSG_ACCOUNT_EXISTS;
        // }

        // this.setState({ error });
      });
  };
  facebookLogin = (event) => {
    event.preventDefault();
    this.props.firebase
      .doSignInWithFacebook()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.props.history.push('/save-the-date');
      })
      .catch((error) => {
        console.log('error', error);
        // if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        //   error.message = ERROR_MSG_ACCOUNT_EXISTS;
        // }

        // this.setState({ error });
      });
  };
  twitterLogin = (event) => {
    event.preventDefault();
    this.props.firebase
      .doSignInWithTwitter()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.props.history.push('/save-the-date');
      })
      .catch((error) => {
        console.log('error', error);
        // if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        //   error.message = ERROR_MSG_ACCOUNT_EXISTS;
        // }

        // this.setState({ error });
      });
  };
  createUser = () => {
    this.props.firebase
      .doCreateUserWithEmailAndPassword(this.email, this.password)
      .then((socialAuthUser) => {
        console.log('user', socialAuthUser);
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.props.history.push('/save-the-date');
      })
      .catch((error) => {
        console.log('error', error);
        // if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        //   error.message = ERROR_MSG_ACCOUNT_EXISTS;
        // }

        // this.setState({ error });
      });
  };

  setObservable = ({ name, event }) => {
    this[name] = event.target.value;
  };

  get validate() {
    if (this.email.length > 3 && this.password.length > 6) return false;
    return true;
  }
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header absolute color="transparent" brand="Forever Frye" {...rest} />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: 'url(' + image + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={10} md={6}>
                <Card className={classes.cardSignup}>
                  <h2 className={classes.cardTitle}>Register</h2>
                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.textCenter}>
                          <h4 className={classes.socialTitle}>
                            Use social media
                          </h4>
                          <Button
                            onClick={this.twitterLogin}
                            justIcon
                            round
                            color="twitter"
                          >
                            <i
                              className={classes.socials + ' fab fa-twitter'}
                            />
                          </Button>
                          {` `}
                          <Button
                            onClick={this.facebookLogin}
                            justIcon
                            round
                            color="facebook"
                          >
                            <i
                              className={classes.socials + ' fab fa-facebook-f'}
                            />
                          </Button>
                          {` `}
                          <Button
                            onClick={this.googleLogin}
                            justIcon
                            round
                            color="google"
                          >
                            <i className={classes.socials + ' fab fa-google'} />
                          </Button>
                          {` `}
                          <h4 className={classes.socialTitle}>
                            or use your name and email.
                          </h4>
                        </div>
                        <form className={classes.form}>
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              value: this.email,
                              onChange: (event) =>
                                this.setObservable({ name: 'email', event }),
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Email
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: 'Email...',
                            }}
                          />
                          <CustomInput
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            helperText={
                              this.password.length < 6
                                ? 'Password must be 6 characters or more'
                                : undefined
                            }
                            inputProps={{
                              type: 'password',
                              error: this.password.length < 6,
                              value: this.password,

                              onChange: (event) =>
                                this.setObservable({ name: 'password', event }),
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className={classes.inputAdornment}
                                >
                                  <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                  </Icon>
                                </InputAdornment>
                              ),
                              placeholder: 'Password...',
                            }}
                          />
                          <div className={classes.textCenter}>
                            <Button
                              disabled={this.validate}
                              onClick={() => this.createUser()}
                              round
                              color="primary"
                            >
                              Get started
                            </Button>
                          </div>
                        </form>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer
            content={
              <div>
                <div className={classes.right}>
                  &copy; {1900 + new Date().getYear()}, made with{' '}
                  <Favorite className={classes.icon} /> by Christina and Zach
                </div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

SignUpPage.propTypes = {
  classes: PropTypes.object,
};

decorate(SignUpPage, {
  email: observable,
  password: observable,
  setObservable: action,
  createUser: action,
  validate: computed,
});

const SignUpPageFirebase = withFirebase(observer(SignUpPage));

export default withStyles(signupPageStyle)(SignUpPageFirebase);
