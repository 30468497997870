import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Hidden from "@material-ui/core/Hidden";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.jsx";

// office
import photo1 from "assets/img/photo1.jpg";
import photo2 from "assets/img/photo2.jpg";
import photo4 from "assets/img/photo4.jpg";
import photo5 from "assets/img/photo5.jpg";
import photo6 from "assets/img/photo6.jpg";

import ridge2 from "assets/img/ridge2.jpg";
import ridge3 from "assets/img/ridge3.jpg";
import ridge4 from "assets/img/ridge4.jpg";
import ridge5 from "assets/img/ridge5.jpg";
import ridge6 from "assets/img/ridge6.jpg";

const desktopData = [
  {
    img: photo1,
    title: "Image",
    author: "author",
    cols: 1
  },
  {
    img: ridge3,
    title: "Image",
    author: "author",
    cols: 2
  },
  {
    img: ridge4,
    title: "Image",
    author: "author",
    cols: 2
  },
  {
    img: photo2,
    title: "Image",
    author: "author",
    cols: 1
  },
  {
    img: ridge6,
    title: "Image",
    author: "author",
    cols: 3
  },
  {
    img: photo4,
    title: "Image",
    author: "author",
    cols: 1
  },
  {
    img: ridge2,
    title: "Image",
    author: "author",
    cols: 1
  },
  {
    img: photo5,
    title: "Image",
    author: "author",
    cols: 1
  },
  {
    img: ridge5,
    title: "Image",
    author: "author",
    cols: 2
  },
  {
    img: photo6,
    title: "Image",
    author: "author",
    cols: 1
  }
];

function SectionOffice(props) {
  const { classes } = props;
  return (
    <div className={classes.office}>
      <GridContainer className={classes.textCenter}>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h1 className={classes.title}>September 4th, 2021</h1>
          <h4 className={classes.description}>
            Ridge Golf Course & Events Center - Auburn, CA
          </h4>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {/* Mobile */}
        <Hidden mdUp implementation="js">
          <GridList cellHeight={400} className={classes.gridList} cols={1}>
            {desktopData.map(tile => (
              <GridListTile key={tile.img} cols={1} imgFullHeight={true}>
                <img src={tile.img} alt={tile.title} style={{ marginTop: 0 }} />
              </GridListTile>
            ))}
          </GridList>
        </Hidden>
        {/* Desktop */}
        <Hidden smDown implementation="js" className={classes.hidden}>
          <GridList cellHeight={320} className={classes.gridList} cols={3}>
            {desktopData.map(tile => (
              <GridListTile
                key={tile.img}
                cols={tile.cols || 1}
                imgFullHeight={true}
              >
                <img src={tile.img} alt={tile.title} style={{ marginTop: 0 }} />
              </GridListTile>
            ))}
          </GridList>
        </Hidden>
      </GridContainer>
      <GridContainer className={classes.textCenter}>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 className={classes.description}>
            <p>
              <i>
                &quot;So they are no longer two, but one flesh. Therefore what
                God has joined together, let no one seperate.&quot;
              </i>
            </p>
            <p>
              <b>&ndash; Matthew 19:6</b>
            </p>
          </h3>
        </GridItem>
      </GridContainer>
    </div>
  );
}

SectionOffice.propTypes = {
  classes: PropTypes.object
};

export default withStyles(officeStyle)(SectionOffice);
