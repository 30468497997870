import { observable, action, computed, decorate } from "mobx";

class MessageStore {
  messages = null;
  limit = 5;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  setMessages = messages => {
    this.messages = messages;
  };

  setLimit = limit => {
    this.limit = limit;
  };

  get messageList() {
    return Object.keys(this.messages || {}).map(key => ({
      ...this.messages[key],
      uid: key
    }));
  }
}

decorate(MessageStore, {
  messages: observable,
  limit: observable,
  setMessages: action,
  setLimit: action,
  messageList: computed
});

export default MessageStore;
