import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

// import Navigation from '../Navigation';
// import LandingPage from '../Landing';
// import SignUpPage from '../SignUp';
// import SignInPage from '../SignIn';
// import PasswordForgetPage from '../PasswordForget';
// import HomePage from '../Home';
// import AccountPage from '../Account';
// import AdminPage from '../Admin';

import SaveTheDate from "./views/SaveTheDate/SaveTheDate";
import LoginPage from "./views/LoginPage/LoginPage";
import SignupPage from "./views/SignupPage/SignupPage";

// import * as ROUTES from '../../constants/routes';
import { withAuthentication, withAuthorization } from "./components/Session";


const App = () => (
  <Router>
    <Switch>
      <Route path="/save-the-date" component={SaveTheDate} />
      <Route path="/login" component={LoginPage} />
      <Route path="/register" component={SignupPage} />

      <Redirect from="/" to="/login" />
      {/* <div>
      <Navigation />

      <hr />

      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route
        path={ROUTES.PASSWORD_FORGET}
        component={PasswordForgetPage}
      />
      <Route path={ROUTES.HOME} component={HomePage} />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route path={ROUTES.ADMIN} component={AdminPage} />
    </div> */}
    </Switch>
  </Router>
);

export default App;
