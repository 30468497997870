import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router';
import { Provider } from 'mobx-react';
import * as firebase from 'firebase';

// pages for this product

import store from './stores';

import App from './App';

import Firebase, { FirebaseContext } from './firebase';

ReactDOM.render(
  <Provider {...store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById('root')
);
