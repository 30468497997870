/*eslint-disable*/
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Favorite from '@material-ui/icons/Favorite';
import Face from '@material-ui/icons/Face';
import { decorate, observable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
// core components
import Header from 'components/Header/Header.jsx';
import HeaderLinks from 'components/Header/HeaderLinks.jsx';
import Footer from 'components/Footer/Footer.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

import loginPageStyle from 'assets/jss/material-kit-pro-react/views/loginPageStyle.jsx';

import image from 'assets/img/banner.jpg';
import { withFirebase } from '../../firebase';
import CardFooter from 'components/Card/CardFooter';

class LoginPage extends React.Component {
  email = '';
  password = '';
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }
  googleLogin = (event) => {
    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.props.history.push('/save-the-date');
      })
      .catch((error) => {
        console.log('error', error);
        // if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        //   error.message = ERROR_MSG_ACCOUNT_EXISTS;
        // }

        // this.setState({ error });
      });

    event.preventDefault();
  };
  facebookLogin = (event) => {
    this.props.firebase
      .doSignInWithFacebook()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.props.history.push('/save-the-date');
      })
      .catch((error) => {
        console.log('error', error);
        // if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        //   error.message = ERROR_MSG_ACCOUNT_EXISTS;
        // }

        // this.setState({ error });
      });

    event.preventDefault();
  };
  twitterLogin = (event) => {
    this.props.firebase
      .doSignInWithTwitter()
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.props.history.push('/save-the-date');
      })
      .catch((error) => {
        console.log('error', error);
        // if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        //   error.message = ERROR_MSG_ACCOUNT_EXISTS;
        // }

        // this.setState({ error });
      });

    event.preventDefault();
  };

  login = () => {
    this.props.firebase
      .doSignInWithEmailAndPassword(this.email, this.password)
      .then((socialAuthUser) => {
        // Create a user in your Firebase Realtime Database too
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.user.displayName,
          email: socialAuthUser.user.email,
          roles: {},
        });
      })
      .then(() => {
        this.props.history.push('/save-the-date');
      })
      .catch((error) => {
        console.log('error', error);
        // if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
        //   error.message = ERROR_MSG_ACCOUNT_EXISTS;
        // }

        // this.setState({ error });
      });
  };
  setObservable = ({ name, event }) => {
    this[name] = event.target.value;
  };

  get validate() {
    if (this.email.length && this.password.length > 6) return false;
    return true;
  }
  render() {
    const { classes, history } = this.props;
    return (
      <div>
        <Header absolute color="transparent" brand="Forever Frye" />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: 'url(' + image + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <form className={classes.form}>
                    <CardHeader
                      color="primary"
                      signup
                      className={classes.cardHeader}
                    >
                      <h4 className={classes.cardTitle}>Login</h4>
                      <div className={classes.socialLine}>
                        <Button
                          justIcon
                          color="transparent"
                          className={classes.iconButtons}
                          onClick={this.twitterLogin}
                        >
                          <i className="fab fa-twitter" />
                        </Button>
                        <Button
                          justIcon
                          color="transparent"
                          className={classes.iconButtons}
                          onClick={this.facebookLogin}
                        >
                          <i className="fab fa-facebook" />
                        </Button>
                        <Button
                          justIcon
                          color="transparent"
                          className={classes.iconButtons}
                          onClick={this.googleLogin}
                        >
                          <i className="fab fa-google" />
                        </Button>
                      </div>
                    </CardHeader>
                    <p
                      className={classes.description + ' ' + classes.textCenter}
                    >
                      Or by email
                    </p>
                    <CardBody signup>
                      <CustomInput
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: this.email,
                          onChange: (event) =>
                            this.setObservable({ name: 'email', event }),

                          placeholder: 'Email...',
                          type: 'email',
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomInput
                        id="pass"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        helperText={
                          this.password.length < 6
                            ? 'Password must be 6 characters or more'
                            : undefined
                        }
                        inputProps={{
                          type: 'password',
                          autoComplete: 'current-password',
                          error: this.password.length < 6,
                          value: this.password,

                          onChange: (event) =>
                            this.setObservable({ name: 'password', event }),

                          placeholder: 'Password',
                          type: 'password',
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon className={classes.inputIconsColor}>
                                lock_utline
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </CardBody>

                    <div className={classes.textCenter}>
                      <Button
                        onClick={() => this.login()}
                        color="primary"
                        size="lg"
                      >
                        Get started
                      </Button>
                    </div>

                    <div className={classes.textCenter}>
                      Not signed up?
                      <Button
                        simple
                        onClick={() => history.push('/register')}
                        color="primary"
                        size="lg"
                      >
                        Register
                      </Button>
                    </div>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer
            className={classes.footer}
            content={
              <div>
                <div className={classes.right}>
                  &copy; {1900 + new Date().getYear()}, made with{' '}
                  <Favorite className={classes.icon} /> by Christina and Zach
                </div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object,
};

decorate(LoginPage, {
  email: observable,
  password: observable,
  setObservable: action,
  login: action,
  validate: computed,
});

const LoginPageFirebase = withFirebase(observer(LoginPage));

export default withStyles(loginPageStyle)(LoginPageFirebase);
